import {ActivatedRouteSnapshot, RouterStateSnapshot, Routes} from '@angular/router';
import {inject} from "@angular/core";
import {LessCoreAuthenticatedGuard} from "@lessname/less-core";

export const appRoutes: Routes = [
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadComponent: () => import('./components/app-home.component').then(mod => mod.AppHomeComponent),
        title: 'app.title.home',
        path: '',
    },
    {
        loadChildren: () => import('../me/app-me.routes').then(m => m.appMeRoutes),
        title: 'app.title.me.main',
        path: 'me',
    },
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadChildren: () => import('../site-clippy/app-content.routes').then(m => m.appContentRoutes),
        path: 'content',
    },
];