export const environment = {
    production: false,
    services: {
        'less-identity': {
            uri: 'https://identity.service.lessname.dev/',
            token: true,
        },
        'less-storage': {
            uri: 'https://storage.service.lessname.dev/',
            token: true,
        },
        'less-site-clippy': {
            uri: 'https://service.siteclippy.dev/',
            token: true,
        },
    },
};

import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
